import Vue from 'vue'
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
Vue.use(VueRouter)
const routes = [{
		path: '/',
		name: 'home',
		component: HomeView
	},
	//支付页面
	{
		path: '/orderPayZh',
		name: 'orderPayZh',
		component: () => import( /* webpackChunkName: "about" */ '../views/orderPayZh.vue')
	},
	//支付成功页面
	{
		path: '/paysuccess',
		name: 'paysuccess',
		component: () => import( /* webpackChunkName: "about" */ '../views/paysuccess.vue')
	},
	//登录注册
	{
		path: '/logIn',
		name: 'logIn',
		component: () => import( /* webpackChunkName: "about" */ '../views/logIn.vue')
	},
	// 在线课程(最新课程)
	{
		path: '/newcourses',
		name: 'newcourses',
		component: () => import( /* webpackChunkName: "about" */ '../views/onlineCourses/newcourses.vue')
	},
	// 在线课程(视频课程)
	{
		path: '/videocourses',
		name: 'videocourses',
		component: () => import( /* webpackChunkName: "about" */ '../views/onlineCourses/videocourses.vue')
	},
	// 在线课程(直播课程)
	{
		path: '/livecourses',
		name: 'livecourses',
		component: () => import( /* webpackChunkName: "about" */ '../views/onlineCourses/livecourses.vue')
	},
	//直播课程页面
	{
		path: '/player',
		name: 'player',
		component: () => import( /* webpackChunkName: "about" */ '../views/onlineCourses/player.vue')
	},
	// 在线课程(系列课程)
	{
		path: '/Latestcourses',
		name: 'Latestcourses',
		component: () => import( /* webpackChunkName: "about" */ '../views/onlineCourses/Latestcourses.vue')
	},
	//线下课程
	{
		path: '/OfflineCourses',
		name: 'OfflineCourses',
		component: () => import( /* webpackChunkName: "about" */ '../views/OfflineCourses/OfflineCourses.vue')
	},
	//课程详情
	{
		path: '/coursesDetail',
		name: 'coursesDetail',
		component: () => import( /* webpackChunkName: "about" */ '../views/onlineCourses/coursesDetail.vue')
	},
	//课程详情(测试视频播放单独加的)
	{
		path: '/practiceVideo',
		name: 'practiceVideo',
		component: () => import( /* webpackChunkName: "about" */ '../views/onlineCourses/practiceVideo.vue')
	},
	//报名表单提交
	{
		path: '/RegistrationForm',
		name: 'RegistrationForm',
		component: () => import( /* webpackChunkName: "about" */ '../views/onlineCourses/RegistrationForm.vue')
	},
	//确认订单（课程购买）
	{
		path: '/Confirmorder',
		name: 'Confirmorder',
		component: () => import( /* webpackChunkName: "about" */ '../views/onlineCourses/Confirmorder.vue')
	},
	//平台专家
	{
		path: '/PlattformExperten',
		name: 'PlattformExperten',
		component: () => import( /* webpackChunkName: "about" */ '../views/PlattformExperten.vue')
	},
	//平台专家详情
	{
		path: '/PlattformExpertendetail',
		name: 'PlattformExpertendetail',
		component: () => import( /* webpackChunkName: "about" */ '../views/PlattformExpertendetail.vue')
	},
	//病例展示
	{
		path: '/Casepresentation',
		name: 'Casepresentation',
		component: () => import( /* webpackChunkName: "about" */ '../views/Casepresentation.vue')
	},
	//病例展示详情
	{
		path: '/CasepresentationDetail',
		name: 'CasepresentationDetail',
		component: () => import( /* webpackChunkName: "about" */ '../views/CasepresentationDetail.vue')
	},
	//平台介绍
	{
		path: '/PlatformIntroduction',
		name: 'PlatformIntroduction',
		component: () => import( /* webpackChunkName: "about" */ '../views/PlatformIntroduction.vue')
	},
	//常见问题
	{
		path: '/commonProblem',
		name: 'commonProblem',
		component: () => import( /* webpackChunkName: "about" */ '../views/commonProblem.vue')
	},
	//联系我们
	{
		path: '/contactUs',
		name: 'contactUs',
		component: () => import( /* webpackChunkName: "about" */ '../views/contactUs.vue')
	},
	//成为会员
	{
		path: '/memberVip',
		name: 'memberVip',
		component: () => import( /* webpackChunkName: "about" */ '../views/memberVip.vue')
	},
	//商城模块
	//商城主页
	{
		path: '/shopIndex',
		name: 'shopIndex',
		component: () => import( /* webpackChunkName: "about" */ '../views/shop/shopIndex.vue')
	},
	//领券中心
	{
		path: '/Vouchercollectioncenter',
		name: 'Vouchercollectioncenter',
		component: () => import( /* webpackChunkName: "about" */ '../views/shop/Vouchercollectioncenter.vue')
	},
	//商品搜索结果
	{
		path: '/shopSearch',
		name: 'shopSearch',
		component: () => import( /* webpackChunkName: "about" */ '../views/shop/shopSearch.vue')
	},
	//商品详情
	{
		path: '/shopDetail',
		name: 'shopDetail',
		component: () => import( /* webpackChunkName: "about" */ '../views/shop/shopDetail.vue')
	},
	//购物车
	{
		path: '/ShoppingCart',
		name: 'ShoppingCart',
		component: () => import( /* webpackChunkName: "about" */ '../views/shop/ShoppingCart.vue')
	},
	//结算订单
	{
		path: '/shopSettle',
		name: 'shopSettle',
		component: () => import( /* webpackChunkName: "about" */ '../views/shop/shopSettle.vue')
	},
	//申请售后
	{
		path: '/applyAfterSales',
		name: 'applyAfterSales',
		component: () => import( /* webpackChunkName: "about" */ '../views/shop/applyAfterSales.vue')
	},
	//售后详情
	{
		path: '/afterOrderDetail',
		name: 'afterOrderDetail',
		component: () => import( /* webpackChunkName: "about" */ '../views/shop/afterOrderDetail.vue')
	},
	//评价订单
	{
		path: '/EvaluateOrders',
		name: 'EvaluateOrders',
		component: () => import( /* webpackChunkName: "about" */ '../views/shop/EvaluateOrders.vue')
	},
	//申请开票
	{
		path: '/Applyinvoice',
		name: 'Applyinvoice',
		component: () => import( /* webpackChunkName: "about" */ '../views/shop/Applyinvoice.vue')
	},
	//开票状态
	{
		path: '/applyZhuangtai',
		name: 'applyZhuangtai',
		component: () => import( /* webpackChunkName: "about" */ '../views/shop/applyZhuangtai.vue')
	},
	//个人中心（主页）
	{
		path: '/about',
		name: 'about',
		component: () => import( /* webpackChunkName: "about" */ '../views/AboutView.vue')
	},
	//关于我们描述
	{
		path: '/aboutustext',
		name: 'aboutustext',
		component: () => import( /* webpackChunkName: "about" */ '../views/aboutustext.vue')
	},
	// 个人中心买会员
	{
		path: '/selfbuyvip',
		name: 'selfbuyvip',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfbuyvip.vue')
	},
	//订单
	{
		path: '/order',
		name: 'order',
		component: () => import( /* webpackChunkName: "about" */ '../views/order.vue')
	},
	//订单详情
	{
		path: '/orderDetail',
		name: 'orderDetail',
		component: () => import( /* webpackChunkName: "about" */ '../views/orderDetail.vue')
	},
	//订单详情
	{
		path: '/selfyouhuiquan',
		name: 'selfyouhuiquan',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfyouhuiquan.vue')
	},
	//个人中心（课程订单中心）
	{
		path: '/selfcenter',
		name: 'selfcenter',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/selfcenter.vue')
	},
	//个人中心（线下课程订单中心）
	{
		path: '/offlineOrder',
		name: 'offlineOrder',
		component: () => import( /* webpackChunkName: "about" */ '../views/OfflineCourses/offlineOrder.vue')
	},
	//个人中心（课程点单详情中心）
	{
		path: '/selfcenterOrderdetail',
		name: 'selfcenterOrderdetail',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/selfcenterOrderdetail.vue')
	},
	//个人中心（课程线下订单详情中心）
	{
		path: '/offlineorderDetail',
		name: 'offlineorderDetail',
		component: () => import( /* webpackChunkName: "about" */ '../views/OfflineCourses/offlineorderDetail.vue')
	},
	//我的收藏
	{
		path: '/collect',
		name: 'collect',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/collect.vue')
	},
	//我的消息
	{
		path: '/message',
		name: 'message',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/message.vue')
	},
	//资质认证
	{
		path: '/QualificationCertification',
		name: 'QualificationCertification',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/QualificationCertification.vue')
	},
	//地址列表
	{
		path: '/address',
		name: 'address',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/address.vue')
	},
	//添加地址
	{
		path: '/addAddress',
		name: 'addAddress',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/addAddress.vue')
	},
	//修改密码
	{
		path: '/editPassword',
		name: 'editPassword',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/editPassword.vue')
	},
	//帮助与反馈
	{
		path: '/Helpfeedback',
		name: 'Helpfeedback',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/Helpfeedback.vue')
	},
	//帮助详情
	{
		path: '/helpDetail',
		name: 'helpDetail',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/helpDetail.vue')
	},
	//意见反馈
	{
		path: '/faceBack',
		name: 'faceBack',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/faceBack.vue')
	},
	//历史反馈
	{
		path: '/fackBackHistory',
		name: 'fackBackHistory',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/fackBackHistory.vue')
	},
	{
		path: '/logistics',
		name: 'logistics',
		component: () => import( /* webpackChunkName: "about" */ '../views/logistics.vue')
	}
	
]

const router = new VueRouter({
	// mode: 'history',
	base: process.env.BASE_URL,
	routes
})
//跳转后返回页面顶部
router.afterEach(() => {
    window.scrollTo(0,0);
})

export default router
