<template>
	<div class="home">
		<HelloWorld></HelloWorld>
		<!-- 轮播图 -->
		<div class="index_swiper">
			<el-carousel trigger="click" height="500px">
				<el-carousel-item v-for="item in bannerList" :key="item.thumb">
					<img :src="item.thumb" alt="" @click="swiperclick">
				</el-carousel-item>
			</el-carousel>
		</div>
		<!-- 第一模块 -->
		<div class="index_two" v-if="liveList.length>0">
			<img class="index_two_bg" src="../assets/icon9.png" alt="">
			<div class="index_two_header">
				<h1>Live Lectures</h1>
				<div class="index_two_header_r" @click="moreclick(3)">
					<span>More Courses</span>
					<img src="../assets/icon10.png" alt="">
				</div>
			</div>
			<div class="index_two_content">
				<div class="index_two_list" v-for="(item,index ) in liveList" :key="index" @click="godetail(3,item.aid)">
					<div class="index_two_list_l">
						<img :src="item.image" alt="">
						<div class="index_two_list_l_bt" v-if="item.is_finish==0">
							<span>{{item.start_time}}Start</span>
						</div>
					</div>
					<div class="index_two_list_r">
						<div class="index_two_list_r_title">
							<span>{{item.course_title}}</span>
						</div>
						<p class="index_two_list_r_name">Lecturers：{{item.videodocent_user_name}}</p>
						<p class="index_two_list_r_bq">Free</p>
					</div>
				</div>
			</div>
		</div>

		<!-- 第二模块 -->
		<div class="index_three">
			<div class="index_two_header">
				<h1>Excellent Courses</h1>
				<div class="index_two_header_r" @click="moreclick(2)">
					<span>More Courses</span>
					<img src="../assets/icon11.png" alt="">
				</div>
			</div>
			<div class="index_three_content">
				<div class="index_three_list" v-for="(item,index) in jinpinlist" :key="index" @click="godetail(2,item.aid)">
					<img :src="item.image" alt="">
					<div class="index_three_list_text">
						<p class="index_three_list_text_name">{{item.course_title}}</p>
						<p class="index_three_list_text_bq">Lecturers：{{item.videodocent_user_name}} | {{item.office}} | {{item.job_title}}</p>
						<div class="index_three_list_text_bottom">
							<div class="index_three_list_text_bottom_l" v-if="item.price !='0.00'">
								<span style="font-size: 12px;">¥</span>
								<span>{{item.price}}</span>
							</div>
							<div class="index_three_list_text_bottom_l" v-if="item.price =='0.00'">
								<span style="font-size: 12px;"></span>
								<span>Free</span>
							</div>
							<div class="index_three_list_text_bottom_c" v-if="item.vip_price !='0.00'">
								<span>VIP exclusive price￥{{item.vip_price}}</span>
							</div>
							<div class="index_three_list_text_bottom_c" v-if="item.price !='0.00' && item.vip_price =='0.00'">
								<span>VIP free</span>
							</div>
							<div class="index_three_list_text_bottom_r">
								<span>{{item.class_hour}}Course Hour</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 第三部分 -->
		<div class="index_four">
			<div class="index_two_header">
				<h1>Offline Courses</h1>
				<div class="index_two_header_r" @click="moreclick(1)">
					<span>More Courses</span>
					<img src="../assets/icon11.png" alt="">
				</div>
			</div>
			<div class="index_four_content">
				<img class="index_four_left" src="../assets/icon18.png" alt="" @click="arrowxxClick('left')">
				<el-carousel height="202px" style="width: 1120px;float: left;margin-left:12px;" ref="cardxxShow"
					arrow="never" :autoplay="false" indicator-position="none">
					<el-carousel-item v-for="(item,index) in xianxialist" :key="index">
						<img class="el-carousel-item_img" :src="list.image" alt="" v-for="(list,smalindex) in item" :key="smalindex" @click="godetail(4,list.aid)">
					</el-carousel-item>
				</el-carousel>
				<img class="index_four_right" src="../assets/icon19.png" alt="" @click="arrowxxClick('right')">
			</div>
		</div>

		<!-- 第四部分 -->
		<div class="index_four">
			<div class="index_two_header">
				<h1>Our Experts</h1>
				<div class="index_two_header_r" @click="moreclick(4)">
					<span>More</span>
					<img src="../assets/icon11.png" alt="">
				</div>
			</div>
			<div class="index_four_content">
				<img class="index_four_left" style="margin-top: 127px;" src="../assets/icon18.png" alt=""
					@click="arrowClick('left')">
				<el-carousel height="280px" style="width: 1120px;float: left;margin-left:12px;" ref="cardShow"
					arrow="never" :autoplay="false" indicator-position="none">
					<el-carousel-item v-for="(item,index) in zhuanjialist" :key="index">
						<div class="index_five_list" v-for="(list,smallindex) in item" :key="smallindex" @click="godetail(5,list.aid)">
							<img :src="list.image" alt="">
							<h3>{{list.user_name}}</h3>
							<p>{{list.job_title}} | {{list.office}}</p>
						</div>
					</el-carousel-item>
				</el-carousel>
				<img class="index_four_right" style="margin-top: 127px;" src="../assets/icon19.png" alt=""
					@click="arrowClick('right')">
			</div>
		</div>
		<footerBottom></footerBottom>
	</div>
</template>

<script>
	import HelloWorld from '@/components/HelloWorld.vue';
	import footerBottom from '@/components/footerBottom.vue';
	export default {
		name: 'HomeView',
		components: {
			HelloWorld,
			footerBottom,
		},
		data() {
			return {
				bannerList: [],//轮播图
				liveList:[],//直播课程
				jinpinlist:[],//精品课程
				xianxialist:[],//线下课程
				zhuanjialist:[],//平台专家
			}
		},
		create() {},
		mounted() {
			this.indexsj(); //首页数据
		},
		methods: {
			indexsj() {
				//轮播图
				this.$http({
					url: '62c7e3aba33c4',
					method: 'get',
					params: {
						type_id:"5"
					},
				}).then((res) => {
					if (res.data.code == 1) {
						this.bannerList = res.data.data;
					}
				});
				
				//获取直播课程
				this.$http({
					url: '63e4b5881383e',
					method: 'get',
					params: {
						is_recommend:"1",
						page:"1",
						list_rows:"3",
					},
				}).then((res) => {
					if (res.data.code == 1) {
						this.liveList = res.data.data.data;
					}
				});
				
				//获取精品课程
				this.$http({
					url: '63e4b6033e463',
					method: 'get',
					params: {
						is_recommend:"1",
						page:"1",
						list_rows:"6",
					},
				}).then((res) => {
					if (res.data.code == 1) {
						this.jinpinlist = res.data.data.data;
					}
				});
				
				//获取线下课程
				this.$http({
					url: '63e4b551be4f0',
					method: 'get',
					params: {
						is_recommend:"1",
						page:"1",
						list_rows:"15",
					},
				}).then((res) => {
					if (res.data.code == 1) {
						let list = res.data.data.data;
						for (let i = 0, l = list.length; i < l; i += 3) {
							this.xianxialist.push(list.slice(i, i + 3));
						}
					}
				});
				
				//获取平台专家
				this.$http({
					url: '63e2190623543',
					method: 'get',
					params: {
						is_recommend:"1",
						page:"1",
						list_rows:"15",
					},
				}).then((res) => {
					if (res.data.code == 1) {
						let list = res.data.data.data;
						for (let i = 0, l = list.length; i < l; i += 5) {
							this.zhuanjialist.push(list.slice(i, i + 5));
						}
					}
				});
			},
			
			//轮播图点击事件
			swiperclick(){
				this.$router.push({path:"PlatformIntroduction"})
			},
			
			//查看更多点击事件
			moreclick(type){
				if(type==1){
					//线下课程
					this.$router.push({
						path: "OfflineCourses",
						query: {
							type: type,
						}
					})
				}else if(type==2){
					//视频课程（精品课程）
					this.$router.push({
						path: "videocourses",
						query: {
							type: type,
						}
					})
				}else if(type==3){
					//直播课程
					this.$router.push({
						path: "livecourses",
						query: {
							type: type,
						}
					})
				}else{
					this.$router.push({
						path: "PlattformExperten",
						query: {
							type: type,
						}
					})
				}
			},
			
			
			
			//线下课程轮播图左右切换
			arrowxxClick(val) {
				if (val === 'right') {
					this.$refs.cardxxShow.next()
				} else {
					this.$refs.cardxxShow.prev()
				}
			},
			
			//平台专家轮播图左右切换
			arrowClick(val) {
				if (val === 'right') {
					this.$refs.cardShow.next()
				} else {
					this.$refs.cardShow.prev()
				}
			},

			//跳转课程详情和平台专家详情
			godetail(index,id) {
				if (index == 5) {
					//平台专家详情
					this.$router.push({
						path: "PlattformExpertendetail",
						query: {
							type: index,
							id:id,
						}
					})
				} else {
					//课程详情
					this.$router.push({
						path: "coursesDetail",
						query: {
							type: index,
							id:id,
						}
					})
				}
			}
		}
	}
</script>
<style scoped>
	.home {
		width: 100%;
		background-color: #fff;
		overflow: hidden;
	}

	.index_swiper {
		width: 100%;
		height: 500px;
		clear: both;
	}

	.index_swiper img {
		width: 100%;
		height: 500px;
	}

	.index_two {
		width: 1200px;
		height: 532px;
		border-radius: 24px;
		margin: 80px auto;
		position: relative;
	}

	.index_two_bg {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
	}

	.index_two_header {
		width: 100%;
		height: 42px;
		line-height: 42px;
		position: relative;
		z-index: 9;
	}

	.index_two_header h1 {
		font-size: 32px;
		font-family: WDCH-Regular, WDCH;
		font-weight: 400;
		color: #333333;
		display: block;
		float: left;
		margin-top: 24px;
		margin-left: 40px;
	}

	.index_two_header_r {
		width: auto;
		height: 24px;
		float: right;
		margin-right: 28px;
		margin-top: 33px;
		font-size: 18px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: 400;
		color: #22AB80;
		cursor: pointer;
	}

	.index_two_header_r img {
		width: 12px;
		height: 16px;
		margin-left: 8px;
		position: relative;
		top: 2px;
	}

	.index_two_content {
		width: 1172px;
		height: 436px;
		background: #FFFFFF;
		border-radius: 24px 24px 24px 24px;
		position: relative;
		margin: 36px auto;
	}

	.index_two_list {
		width: 31.5%;
		height:410px;
		background: #F5F7FA;
		border-radius: 16px;
		float: left;
		margin-left: 16px;
		margin-top: 16px;
		cursor: pointer;
	}

	.index_two_list_l {
		width: 100%;
		height:200px;
		position: relative;
		float: left;
	}

	.index_two_list_l img {
		width: 100%;
		height: 100%;
		border-radius: 16px;
	}

	.index_two_list_l_bt {
		width: 220px;
		height: 24px;
		background-color: rgba(0, 0, 0, 0.5);
		border-radius: 0px 0px 16px 16px;
		position: absolute;
		left: 0;
		bottom: 0;
		font-size: 12px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 24px;
		text-indent: 20px;
	}

	.index_two_list_r {
		width: 100%;
		height: 160px;
		float: left;
	}

	.index_two_list_r_title {
		width: 290px;
		height: 48px;
		overflow: hidden;
		font-size: 16px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		line-height: 26px;
		margin-top: 12px;
		margin-left: 20px;
	}

	.index_two_list_r .index_two_list_r_name {
		font-size: 14px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: 400;
		color: #999999;
		margin-left: 20px;
		margin-top: 10px;
	}

	.index_two_list_r_bq {
		margin-left: 20px;
		margin-top: 10px;
		font-size: 14px;
		font-family: Microsoft YaHei-Bold, Microsoft YaHei;
		font-weight: bold;
		color: #FF3434;
	}

	.index_three {
		width: 1200px;
		overflow: hidden;
		margin: 56px auto;
	}

	.index_three .index_two_header h1 {
		font-size: 32px;
		font-family: Microsoft YaHei-Bold, Microsoft YaHei;
		font-weight: bold;
		color: #333333;
		margin-left: 0;
	}

	.index_three .index_two_header_r {
		font-size: 18px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		margin-right: 0;
	}

	.index_three_content {
		width: 100%;
		overflow: hidden;
	}

	.index_three_list {
		width: 380px;
		height: 370px;
		background: #FFFFFF;
		box-shadow: 0px 0px 20px 1px rgba(0, 90, 201, 0.1);
		border-radius: 16px;
		float: left;
		margin-top: 32px;
		margin-right: 30px;
		cursor: pointer;
	}

	.index_three_list:nth-child(3n) {
		margin-right: 0;
	}

	.index_three_list img {
		width: 100%;
		height: 214px;
		border-radius: 16px;
	}

	.index_three_list_text {
		width: 360px;
		height: 156px;
		margin: 0 auto;
	}

	.index_three_list_text_name {
		font-size: 16px;
		font-family: Microsoft YaHei-Bold, Microsoft YaHei;
		font-weight: bold;
		color: #333333;
		line-height: 26px;
	}

	.index_three_list_text_bq {
		font-size: 14px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: 400;
		color: #999999;
		margin-top: 16px;
	}

	.index_three_list_text_bottom {
		width: 100%;
	}

	.index_three_list_text_bottom_l {
		font-size: 18px;
		font-family: Microsoft YaHei-Bold, Microsoft YaHei;
		font-weight: bold;
		color: #FF3434;
		float: left;
		margin-top: 16px;
	}

	.index_three_list_text_bottom_c {
		width: auto;
		height: 22px;
		background: #FFF3DC;
		border-radius: 4px 4px 4px 4px;
		font-size: 14px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: 400;
		color: #CB913B;
		float: left;
		text-align: center;
		margin-top: 16px;
		margin-left: 16px;
		padding-left: 10px;
		padding-right: 10px;
	}

	.index_three_list_text_bottom_r {
		font-size: 14px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: 400;
		color: #999999;
		float: right;
		margin-top: 16px;
	}

	.index_four {
		width: 1200px;
		overflow: hidden;
		margin: 56px auto;
	}

	.index_four .index_two_header h1 {
		font-size: 32px;
		font-family: Microsoft YaHei-Bold, Microsoft YaHei;
		font-weight: bold;
		color: #333333;
		margin-left: 0;
	}

	.index_four .index_two_header_r {
		color: #333333;
		margin-right: 0;
	}

	.index_four_content {
		width: 100%;
		height: 202px;
		margin-top: 48px;
		cursor: pointer;
	}

	.index_four_left {
		width: 28px;
		height: 28px;
		display: block;
		float: left;
		margin-top: 87px;
	}

	.index_four_right {
		width: 28px;
		height: 28px;
		display: block;
		float: right;
		margin-top: 87px;
	}

	.el-carousel-item_img {
		width: 360px !important;
		height: 100% !important;
		display: block;
		float: left;
		margin-right: 20px;
	}

	.el-carousel-item_img:last-child {
		margin-right: 0;
	}

	.index_five_list {
		width: 200px;
		height: 280px;
		background: #FFFFFF;
		box-shadow: 0px 0px 20px 1px rgba(0, 90, 201, 0.1);
		border-radius: 16px 16px 16px 16px;
		float: left;
		margin-right: 30px;
	}

	.index_five_list:nth-child(5n) {
		margin-right: 0;
	}

	.index_five_list img {
		width: 141px;
		height: 173px;
		display: block;
		/* border-radius: 16px; */
		margin: 0 auto;
	}

	.index_five_list h3 {
		font-size: 16px;
		font-family: Microsoft YaHei-Bold, Microsoft YaHei;
		font-weight: bold;
		color: #333333;
		text-indent: 16px;
		margin-top: 10px;
		margin-bottom: 6px;
	}

	.index_five_list p {
		font-size: 14px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: 400;
		color: #999999;
		text-indent: 16px;
	}
</style>
